<template>
  <div id="map-container"></div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

var AMap = null;

export default {
  props: {
    list: {
      type: Array,
      // default: () => [{ lat: 39.921841, lng: 116.409797 }],
    },
  },
  data() {
    return {
      map: null,
      markerList: [],
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    list() {
      this.addMarker();
    },
  },
  methods: {
    init() {
      AMapLoader.load({
        key: "7928f1a412eb182deed0f33983d4ea71", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((_AMap) => {
          AMap = _AMap;
          this.map = new AMap.Map("map-container", {
            zoom: 17, //  地图显示的缩放级别
            zooms: [4, 22], // 地图缩放范围
            center: [116.411963, 39.921583], //  地图中心点坐标 此处填【经度，纬度】
            resizeEnable: true, //  是否监控地图容器尺寸变化
          });
          var marker = new AMap.Marker({
            position: new AMap.LngLat(116.411963, 39.921583), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: "北京人民艺术剧院",
            label: {
              content: "<div class='map-center-title'>北京人民艺术剧院</div>",
              offset: new AMap.Pixel(-90, -50),
            },
          });
          this.map.add(marker);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    addMarker() {
      if (this.list && this.list.length && AMap) {
        this.map.remove(this.markerList);
        this.markerList = [];
        this.list.forEach((item) => {
          if (item.lng && item.lat) {
            let marker = new AMap.CircleMarker({
              center: new AMap.LngLat(item.lng, item.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
              radius: 9,
              strokeColor: "#DF1518",
              strokeOpacity: 1,
              strokeWeight: 7,
              fillColor: "#DF1518",
            });
            marker.on("click", () => {
              var infoWindow = new AMap.InfoWindow({
                anchor: "top-left",
                content: `<div class="map-list-item">
                            <img src="${item.image}" />
                            <div class="content">
                              <p class="conten-t1">${item.name || ""}</p>
                              <p class="conten-t2">${item.descript || ""}</p>
                              <p class="conten-t3">
                                电话：<span class="color-primary">${
                                  item.phone || ""
                                }</span>
                              </p>
                              <p class="t4">地址：${item.address || ""}</p>
                            </div>
                          </div>`,
              });
              infoWindow.open(this.map, [item.lng, item.lat]);
            });
            this.markerList.push(marker);
          }
        });
        this.map.add(this.markerList);
      }
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");
#map-container {
  width: 67.7083vw;
  height: 32.8125vw;
}
.amap-marker-label {
  border: 0;
  background-color: transparent;
}
.map-center-title {
  width: 10.4167vw;
  height: 2.0833vw;
  font-size: 1.0417vw;
  text-align: center;
  line-height: 2.0833vw;
  background: #f9680d;
  color: #ffffff;
  border-radius: 0.1042vw;
  box-shadow: 0 0.1042vw 0.2083vw 0 rgba(0, 0, 0, 0.1);
}
.map-list-item {
  width: 14.5833vw;
  margin-top: 0.5208vw;
  img {
    width: 14.5833vw;
    height: 10.9375vw;
    display: block;
  }
  .content {
    box-sizing: border-box;
    height: 6.25vw;
    padding: 0.5208vw;
    background: #f8f8fa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      color: #212122;
      .text-overflow(1);
    }
    .conten-t1 {
      font-size: 1.0417vw;
      line-height: 1.0417vw;
      font-weight: 800;
    }
    .conten-t2 {
      font-size: 0.7292vw;
    }
    .conten-t3 {
      font-size: 0.8333vw;
      padding-top: 0.4167vw;
      border-top: 0.0521vw solid #d8dade;
    }
    .t4 {
      font-size: 0.625vw;
      color: #909399;
    }
  }
}
</style>
