<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 22:15:51
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-22 19:42:35
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\guide\index.vue
 * @Description: 观影指南
 *
-->
<template>
  <div
    class="fixed-page culture-guide-index"
    v-infinite-scroll="handleMore"
    :infinite-scroll-distance="100"
  >
    <Breadcrumb class="breadcrumb-1400" />
    <div class="container">
      <div class="title">
        <c-image :src="require('@/assets/img/performance/title-icon.png')" />
        <span>观剧指南</span>
      </div>
      <div class="map-box">
        <Map :list="list" />
        <div class="tab-list">
          <span
            v-for="item in tabList"
            :key="item.value"
            :class="['tab', { active: item.value === activeTab }]"
            @click="handleTab(item)"
            >{{ item.label }}</span
          >
        </div>
      </div>
      <div v-if="activeTab !== 'desc'" class="list">
        <div v-for="item in list" :key="item.id" class="list-item">
          <c-image :src="item.image" />
          <div class="content">
            <p class="conten-t1">{{ item.name }}</p>
            <p class="conten-t2">{{ item.descript }}</p>
            <p class="conten-t3">
              电话：<span class="color-primary">{{ item.phone }}</span>
            </p>
            <p class="t4">地址：{{ item.address }}</p>
          </div>
        </div>
      </div>
      <div v-else class="notice">
        <!-- <div class="custom-title">
          <span>购票须知</span>
        </div> -->
        <div class="html-content" v-html="desc"></div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getServicePage, getArticlePage } from "@/service/culture";
import Map from "./components/Map.vue";
export default {
  components: { Map },
  data() {
    return {
      activeTab: this.$route.query.tab || "",
      // tabList: [
      //   { value: "FOOD", label: "周边美食" },
      //   { value: "HOTEL", label: "周边酒店" },
      //   { value: "SCENERY", label: "周边景点" },
      //   { value: "SHOPPING", label: "周边购物" },
      //   { value: "desc", label: "购票须知" },
      // ],
      list: [],
      pager: {
        pageNo: 1,
        pageSize: 10,
      },
      desc: "",
    };
  },
  created() {
    this.$store.dispatch("getDict", ["zhoubian"]);
    this.getDesc();
  },
  computed: {
    tabList() {
      let list = this.$store.state.dictMap["zhoubian"] || [];
      list = list.map((item) => {
        return { value: item.id, label: item.value };
      });
      list.push({ value: "desc", label: "购票须知" });
      return list;
    },
  },
  watch: {
    tabList() {
      if (!this.activeTab && this.tabList.length > 0) {
        this.activeTab = this.tabList[0].value;
      }
    },
    activeTab() {
      this.activeTab !== "desc" && this.handleSearch();
    },
  },
  methods: {
    // 获取购票须知
    async getDesc() {
      const res = await getArticlePage({
        pageNo: 1,
        pageSize: 1,
        condition: {
          level1: "culture",
          level2: "GPXZ",
          status: 1,
        },
      });
      const data = res.data?.data || [];
      if (data.length) {
        this.desc = data[0].body;
      }
    },
    handleTab(item) {
      this.activeTab = item.value;
      this.activeTab !== "desc" && this.handleSearch();
    },
    // 获取周边
    async getData() {
      try {
        const res = await getServicePage({
          ...this.pager,
          condition: { serviceId: this.activeTab },
        });
        if (res.data) {
          const data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (this.activeTab === "desc") return;
      clearTimeout(this.timer);
      if (!this.hasNextPage) return;
      this.timer = setTimeout(() => {
        this.pager.pageNo++;
        this.getData();
      }, 1000);
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-guide-index {
  p {
    padding: 0;
    margin: 0;
  }
  .container {
    background-color: #ffffff;
    margin: 0 auto 2.6042vw;
    width: 72.9167vw;
    padding: 2.6042vw;
    box-sizing: border-box;
    .title {
      font-size: 1.3542vw;
      font-weight: 500;
      color: #212122;
      padding-left: 1.3542vw;
      position: relative;
      .img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.7292vw;
        height: 1.3542vw;
      }
    }
    .map-box {
      width: 67.7083vw;
      height: 32.8125vw;
      margin: 1.5625vw 0 2.3438vw;
      background-color: #ffffff;
      position: relative;
    }
    .tab-list {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 67.7083vw;
      height: 2.6042vw;
      background: rgba(248, 211, 159, 0.8);
      display: flex;
      align-items: center;
      font-size: 0.8333vw;
      .tab {
        flex: 1;
        height: 2.6042vw;
        line-height: 2.6042vw;
        text-align: center;
        cursor: pointer;
        &:hover,
        &.active {
          background: #f9680d;
          color: #ffffff;
        }
      }
    }
    .list {
      display: grid;
      gap: 2.6042vw;
      grid-template-columns: repeat(3, 20.8333vw);
      .list-item {
        width: 20.8333vw;
        height: 26.3021vw;
        .img {
          width: 20.8333vw;
          height: 15.625vw;
        }
        .content {
          box-sizing: border-box;
          height: 11.4583vw;
          padding: 1.0417vw 1.5625vw;
          background: #f8f8fa;
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          p {
            color: #212122;
            // .text-overflow(1);
          }
          .conten-t1 {
            font-size: 1.0417vw;
            line-height: 1.0417vw;
            font-weight: 800;
          }
          .conten-t2 {
            font-size: 0.7292vw;
            margin: 0.5208vw 0;
          }
          .conten-t3 {
            font-size: 0.8333vw;
            padding-top: 0.7292vw;
            margin-top: 0.3125vw;
            border-top: 0.0521vw solid #d8dade;
          }
          .t4 {
            font-size: 0.625vw;
            color: #909399;
          }
        }
      }
    }
    .notice {
      .custom-title {
        text-align: left;
        padding-left: 0.6771vw;
        font-size: 0.9375vw;
        font-weight: 800;
        color: #212122;
        line-height: 0.9375vw;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 0.1563vw;
          height: 0.8333vw;
          background: #21424b;
          position: absolute;
          left: 0;
          top: 0.1042vw;
        }
      }
      .html-content {
        margin: 1.5625vw 0;
        line-height: 1.7708vw;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
